import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Search from './search'
import { FiSearch } from "react-icons/fi";

const Sidebar = (props) => {

    const getData = graphql`{
        allWordpressTag {
            distinct(field: id)
            nodes {
              wordpress_id
              slug
              name
              count
            }
        }

        allWordpressCategory {
            distinct(field: id)
            nodes {
              wordpress_id
              slug
              name
              count
              description
            }
          }  
    } `
    const data = useStaticQuery(getData)
    const categoryList= data.allWordpressCategory.nodes;
    const tagList= data.allWordpressTag.nodes;

    const useForm = ( initialState = {} ) => {
        const [values, setValues] = useState(initialState);

        const reset = () => {
            setValues( initialState );
        }

        const handleInputChange = ({ target }) => {
            setValues({
                ...values,
                [ target.name ]: target.value
            });
        }

        return [ values, handleInputChange, reset ];
    }

    const [ formValues, handleInputChange ] = useForm({
        searchText: ''
    })

    const { searchText } = formValues;

    const handleSearch = (e) => {
        e.preventDefault();
        props.passChildData(() => 
            !searchText ? '' : ( <Search searchText={searchText} />
            ))
    }

    return (
        <div className="blog-sidebar">
            <form className="search-form" onSubmit={handleSearch}>
                <input 
                    className="form-control search"
                    id="tableSearch"
                    type="text"
                    name="searchText"
                    value={searchText}
                    onChange={handleInputChange}
                    placeholder="Busca en nuestro blog"
                />
                <button className="btn-search" type="submit"><FiSearch aria-label="Buscar" /></button>
            </form>

            <div className="categorias">
                <h3 className="sidebar-title">Categorias</h3>

                <ul className="list-group list-group-flush">
                    {categoryList.map( (cat, index) => {
                        if (cat.count > 0){
                            return(
                                <li key={index} className={`list-group-item ${(cat.wordpress_id===props.category) ? 'active' : ''}`}>
                                    <Link to={`/blog/${cat.slug}`}>{cat.name}</Link>
                                </li>
                            )
                        }
                        else{
                            return ''
                        }
                    })}
                </ul>
            </div>

            <div className="etiquetas">
                <h3 className="sidebar-title">Etiquetas</h3>
                <ul className="tag-container">
                    {tagList.map( (tag, index) => {
                        if (tag.count > 0){
                            return(
                                <li key={index} className={`tag-item ${(tag.wordpress_id===props.tag) ? 'active' : ''}`}>
                                    <Link className="btn-tag" to={`/tag/${tag.slug}`}>{tag.name}</Link>
                                </li>
                            )
                        }
                        else{
                            return ''
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
