import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PostItem from '../components/blog-post-item'

const Search = props => {

    const getData = graphql`{
        allWordpressPost {
            edges {
                node {
                    excerpt
                    wordpress_id
                    date(formatString: "DD/MM/yyyy")
                    title
                    content
                    slug
                    categories {
                        wordpress_id
                        name
                        slug
                    }
                    tags {
                        wordpress_id
                        name
                        slug
                    }
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                resolutions {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressTag {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    count
                }
            }
        }

        allWordpressCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    count
                }
            }
        }
    } `
    const data = useStaticQuery(getData)


    let filteredTags = []
    let filteredCategories = []
    let filteredPost = []
    let filteredData = {}

    data.allWordpressPost.edges.map(post => {
        if(post.node.title.toLowerCase().includes(props.searchText.toLowerCase())) {
            filteredPost.push(post.node.wordpress_id)
        }

        if(post.node.content.toLowerCase().includes(props.searchText.toLowerCase())) {
            filteredPost.push(post.node.wordpress_id)
        }

        return ''
    })

    data.allWordpressTag.edges.map(tags => {
        if(tags.node.name.toLowerCase().includes(props.searchText.toLowerCase())) {
            filteredTags.push(tags.node.wordpress_id)
        }

        return ''
    })

    data.allWordpressCategory.edges.map(categories => {
        if(categories.node.name.toLowerCase().includes(props.searchText.toLowerCase())) {
            filteredCategories.push(categories.node.wordpress_id)
        }

        return ''
    })

    data.allWordpressPost.edges.map(post => {
        if(post.node.categories) {
            post.node.categories.map(categories => {
                filteredCategories.map(id => {
                    if(categories.wordpress_id === id) {
                        filteredData[post.node.wordpress_id] = post.node
                    }

                    return ''
                })

                return ''
            })
        }

        if(post.node.tags) {
            post.node.tags.map(tags => {
                filteredTags.map(id => {
                    if(tags.wordpress_id === id) {
                        filteredData[post.node.wordpress_id] = post.node
                    }

                    return ''
                })

                return ''
            })
        }

        filteredPost.map(id => {
            if(post.node.wordpress_id === id) {
                filteredData[post.node.wordpress_id] = post.node
            }

            return ''
        })

        return ''
    })

    return (
        !Object.values(filteredData)[0] ? 
            (<div className="no-results"><h1> No hay resultados de: {props.searchText}</h1></div>) 
            : 
            Object.values(filteredData).map(post => (

                <PostItem {...post} />  
            ))
    )


}

export default Search
