import React from "react";
import { Link } from 'gatsby'

const PostItem = (post) => {

	return (
		<article key={post.wordpress_id} className="post-item">
			
				{post.featured_media && post.featured_media.localFile && 
					(	
						<Link to={`/${post.categories[0].slug}/${post.slug}`}>
							<img src={post.featured_media.localFile.childImageSharp.resolutions.src} className="post-thumbnail" alt={post.featured_media.alt_text}/>
						</Link>
					)
				}
				<div className="post-content">
					<aside className="post-info">
						<span className="post-date">{post.date}</span> 

						<span className="post-category">
							<Link to={`/blog/${post.categories[0].slug}`}>
								{post.categories[0].name}
							</Link>
						</span>
					</aside>
					<h3 className="post-title"><Link to={`/${post.categories[0].slug}/${post.slug}`}>{post.title}</Link></h3>
					<div className="post-excerpt" dangerouslySetInnerHTML={{__html: post.excerpt}}/>
				</div>
			
		</article>
	)
}

export default PostItem