import React, { useState } from "react";
import { useStaticQuery, graphql } from 'gatsby'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PostItem from '../components/blog-post-item'

export default ({ pageContext }) => {  

    const [childData, setChildData] = useState("");

    const getData = graphql`{
        allWordpressPost {
            edges {
                node {
                    categories {
                        wordpress_id
                        name
                        slug
                    }                    
                    tags {
                        id
                        name
                        wordpress_id
                    }
                    wordpress_id
                    title
                    slug
                    date(formatString: "DD/MM/yyyy")
                    excerpt
                    featured_media {
                        localFile {
                            childImageSharp {
                                resolutions {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
    } `

    const data = useStaticQuery(getData);

    return (
        <Layout className="page-blog page-blog-list">
            <Seo 
                title="Blog de DMS empresa de Servicios Informáticos de Barcelona"
                description="Blog de DMS sobre IBM Informix, Workforce Productivity, SP-Expert Sistemas, ITIL, Lean Startup y Cloud"
                />
            <section className="container blog-content">
                <div className="blog-content-main">
                    {
                        childData ? childData :
                        data.allWordpressPost.edges.map( item => {
                            const post = item.node;
                            return(
                                !post.tags ? '' : post.tags.map( tag => (
                                    tag.wordpress_id === pageContext.wordpress_id ? (
                                           <PostItem {...post } />
                                    ) : ''
                                ))
                        )})
                    }
                </div>
                <div className="blog-sidebar">
                    <Sidebar tag={pageContext.wordpress_id} passChildData={setChildData}/>
                </div>
            </section>
        </Layout>
    )
}


